<template>
    <div class="c-numpad">
        <div class="c-numpad__display">
            <!-- &nbsp;{{number}}&nbsp;{{pageTitle}}&nbsp; -->
            <span v-if="number" class="c-numpad__number" aria-hidden="true">{{number}}</span><span v-if="number" class="c-numpad__title" aria-hidden="true">{{pageTitle}}</span>
            <span v-else class="c-numpad__title" aria-hidden="true">{{ $t('global.srEnterNumber') }}</span>
          <div class="c-numpad__sr sr-only" aria-live="polite" role="status">
            <form @submit="onSelectPage">
              <input id="numpad__enterNumber" v-model="editNumber" :type="this.$xp.settings.mergedSettings.navigation.numpadInputExtraButtons.length ? 'text' : 'number'" :aria-label="$t('global.srNumber')" @input="onChange">
              <div v-if="hint" role="alert">
                {{ hint }}
              </div>
            </form>
          </div>
        </div>
        <div class="c-numpad__pad" aria-hidden="true">
            <button id="c-numpad__1" class="c-numpad__button link" @click="onNumClick('1')" :disabled="!isNumEnabled('1')">1</button>
            <button id="c-numpad__2" class="c-numpad__button link" @click="onNumClick('2')" :disabled="!isNumEnabled('2')">2</button>
            <button id="c-numpad__3" class="c-numpad__button link" @click="onNumClick('3')" :disabled="!isNumEnabled('3')">3</button>
            <button id="c-numpad__4" class="c-numpad__button link" @click="onNumClick('4')" :disabled="!isNumEnabled('4')">4</button>
            <button id="c-numpad__5" class="c-numpad__button link" @click="onNumClick('5')" :disabled="!isNumEnabled('5')">5</button>
            <button id="c-numpad__6" class="c-numpad__button link" @click="onNumClick('6')" :disabled="!isNumEnabled('6')">6</button>
            <button id="c-numpad__7" class="c-numpad__button link" @click="onNumClick('7')" :disabled="!isNumEnabled('7')">7</button>
            <button id="c-numpad__8" class="c-numpad__button link" @click="onNumClick('8')" :disabled="!isNumEnabled('8')">8</button>
            <button id="c-numpad__9" class="c-numpad__button link" @click="onNumClick('9')" :disabled="!isNumEnabled('9')">9</button>
            <button id="c-numpad__0" class="c-numpad__button link" @click="onNumClick('0')" :disabled="!isNumEnabled('0')">0</button>
            <button id="c-numpad__back" class="c-numpad__button link" @click="onDelClick()" :disabled="this.number.length === 0" :title="$t('global.numPadBack')" area-label="$t('global.numPadBack')">
                <span class="xp xp--large xp-backspace" aria-hidden="true"></span>
            </button>
            <button id="c-numpad__enter" class="c-numpad__button link" ref="enter" :disabled="!selectedPage" @click="onSelectPage" :title="$t('global.numPadEnter')" area-label="$t('global.numPadEnter')">
                <span class="xp xp--large xp-check" aria-hidden="true"></span>
            </button>
            <button v-for="(char, index) in this.$xp.settings.mergedSettings.navigation.numpadInputExtraButtons" :key="index" class="c-numpad__button link" @click="onNumClick(char)" :disabled="!isNumEnabled(char)">{{ char }}</button>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    beacons: {
      required: true
    }
  },
  data () {
    return {
      number: '',
      editNumber: '',
      validChars: [],
      ready: false,
      hint: '',
      hintTimeout: null
    }
  },
  mounted () {
    this.updateValidChars()
  },
  computed: {
    selectedPage () {
      return this.number ? this.beacons[this.number] : null
    },
    pageTitle () {
      if (this.beacons[this.number]) {
        const title = this.beacons[this.number].title.replace(new RegExp('^' + this.number + ' '), '')
        return title
      }
      return ''
    }
  },
  methods: {
    getValidChars () {
      const position = this.number.length
      const validChars = {}
      for (const beacon in this.beacons) {
        if (beacon.length >= position && beacon.substr(0, position) === this.number && beacon.charAt(position) > '') {
          validChars[beacon.charAt(position)] = 0
        }
      }
      // console.log('GetValidChars:', Object.keys(validChars))
      return Object.keys(validChars)
    },
    updateValidChars () {
      this.validChars = this.getValidChars()
    },
    onNumClick (num) {
      this.number = this.number + num
    },
    onDelClick () {
      if (this.number.length > 0) {
        this.number = this.number.slice(0, -1)
      }
    },
    isNumEnabled (num) {
      return (this.validChars.indexOf(num) >= 0)
    },
    onSelectPage (e) {
      if (this.selectedPage) {
        this.$router.push('/page/' + this.selectedPage.pageID)
      } else {
        this.setHint(this.$t('global.srNumberIncomplete') + ' ' + this.$t('global.srValidDigits') + ' ' + this.validChars.join(', ') + '.')
        if (window.navigator.vibrate) window.navigator.vibrate(600)
      }
      if (e) e.preventDefault()
      return false
    },
    setHint (hint) {
      if (this.hintTimeout) clearTimeout(this.hintTimeout)
      this.hintTimeout = setTimeout(() => {
        this.hint = hint
      }, 300)
    },
    onChange (e) {
      this.hint = ''
      const $input = e.target
      const val = $input.value
      const regex = new RegExp(/^(?:[0-9]|[a-z])*$/, 'i')
      const valid = regex.test(val) && (!e.data || regex.test(e.data))

      if (!e.data && valid && val.length < this.number.length) {
        // Letztes Zeichen geloescht
        this.number = val
      } else if (!valid) {
        // Ungueltiges Zeichen eingegeben
        this.editNumber = $input.value = this.number
        this.setHint(this.$t('global.srWrongCharacter') + ' ' + this.$t('global.srValidDigits') + ' ' + this.validChars.join(', ') + '.')
      } else {
        // Gueltige Eingabe
        this.number = val
      }
    }
  },
  watch: {
    number (val) {
      const validChars = this.getValidChars()
      if (val.length > 0) {
        if (validChars.length || Object.keys(this.beacons).includes(val)) {
          this.validChars = validChars
          if (validChars.length === 0) {
            this.setHint(this.$t('global.srConfirmNumber').replace('<pageTitle>', this.pageTitle))
          }
        } else {
          val = val.slice(0, -1)
          this.number = val
          this.editNumber = val
          this.setHint(this.$t('global.srWrongDigit') + ' ' + this.$t('global.srValidDigits') + ' ' + this.validChars.join(', ') + '.')
        }
      } else {
        this.validChars = validChars
      }
      // console.log('watch number:', this.number, ', validChars:', this.validChars.join(','))
    },
    beacons (val) {
      this.ready = !!val && Object.keys(val).length > 0
      this.updateValidChars()
    }
  }
}
</script>
